body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main-home-bg {
  background-image: url('./images/hero-bg-2-1.png');
}

.tp-breadcrumb__area {
  background-image: url('./assets/img/breadcrumb/breadcrumb.png');
}
/* Css for msg component */
.container {
  margin-top: 20px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.chat-header {
  background-color: #f8f9fa;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.tp-gradiant-section-subtitle {
  font-size: 18px;
  font-weight: bold;
}

.line {
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.chat-messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
}

.chat-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f8f9fa;
}

.chatinput {
  border-radius: 25px;
  padding: 10px;
  border: 1px solid #ddd;
  margin-right: 10px;
  flex: 1;
}

.send-button {
  border-radius: 50%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

.send-button i {
  font-size: 20px;
}

.send-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .chat-container {
    height: auto;
  }

  .chat-input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .chatinput {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .send-button {
    width: 100%;
    border-radius: 25px;
  }
}

/* For messages */
.chat-messages {
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.chat-message.user {
  background-color: #e1ffc7;
  align-self: flex-start;
}

.chat-message.expert {
  background-color: #c7d8ff;
  align-self: flex-end;
}

.message-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.85em;
}

.message-author {
  font-weight: bold;
}

.message-time {
  color: gray;
}

.message-text {
  font-size: 1em;
}

/* navlink */
.nav-links button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.vh-100 {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

.scrollable-content {
  height: 100%;
  overflow-y: auto;
  padding-right: 15px; 
}

.scrollable-content::-webkit-scrollbar {
  display: none;
}

.scrollable-content {
  scrollbar-width: none; 
}



