.login{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgb(237,242,248);
    display: grid;
    padding: 20px 20px;
}
.login-container{
    place-self: center;
    width: max(30vw,330px);
    color: #808080;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 30px;
    border-radius: 10px;
    /* border: 2px solid gray; */
    font-size: 14px;
    animation: fadeIn 0.5s
}
.login-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}
.login-title img{
    width: 15px;
    cursor: pointer;
}
.login-input{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.login-input input{
    outline: none;
    height: 30px;
    border: 1px solid #c9c9c9;
    padding: 10px;
    border-radius: 4px;
}
.login-container button{
     border: none;
     padding: 10px;
     border-radius: 4px;
     color: white;
     background-color: rgb(211,64,191);
     font-size: 15px;
     cursor: pointer;
}
.login-condition{
    display: flex;
    /* align-items: start; */
    gap: 8px;
    margin-top: 15px;
}
.login-condition input{
    margin-top: 3px;
}
.login-container p span{
    color: rgb(211,64,191);
    font-weight: 500;
    cursor: pointer;
}